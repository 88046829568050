
/* define font faces */
@font-face {
  font-family: Lato-Light;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

@font-face {
  font-family: Lato-Black;
  src: url(../fonts/Lato/Lato-Black.ttf);
}

/* define fade in animation */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start with 0 opacity */
  }
  to {
    opacity: 1; /* Fade in to full opacity */
  }
}

.fade-in {
  opacity: 0; /* Start with 0 opacity */
  animation: fadeIn 2.75s ease-in-out forwards; /* 1s duration, ease-in-out timing function */
}

h1 {
  font-family: "Inter", sans-serif !important;
  font-size: 2.5rem;
}

/* set padding for mission and events sections */
.mission,
.events {
  padding: 1rem 2rem; /* top&bottm left&right */
}

/* set padding inside mission-statement-container, mission-item container, and events-container*/
.mission-statement-container,
.events-container {
  padding: 2rem;
}

/* ----- hero ----- */
.hero {
  overflow: hidden;
  height: 35rem;
  width: 100%;
  position: relative;
}

.hero--background {
  background-image: url(../images/misc/networking.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  height: 38rem;
  width: 100%;
  filter: blur(5px);
  transform: scale(1.05);
}

.extra-container {
  padding: 0vh 8vw;
}

.hero--foreground {
  /* color: white;
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 1rem;
    text-align: left; */

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  z-index: 1;
  color: white;
}

.hero-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.hero-img {
    width: 500px ;
    height: auto;
}

@media (max-width: 780px) {
    .hero-img {
        width: 100% !important;
        height: auto;
    }

    .hero--text {
        font-size: 0.8rem !important;
    }

    .mission-statement--p {
      font-size: 1.2rem !important;
      line-height: 1.4rem !important;
  }
}

.hero--text {
  font-size: 1.2rem;
  padding-top: 1.2vh;
}

/* ----- carousel ----- */
.slide {
  overflow: hidden; /* Hide scrollbars */
}

/* ----- mission ----- */
.mission {
  background-color: white;
  color: black;
  text-align: center;
  padding: 10vh 0vw;
}

.mission-statement-container {
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
}

.mission-statement {
  width: 600px;
}

.mission-statement p {
  padding: 1rem;
}

.mission-statement--p {
  font-size: 1.4rem;
  line-height: 1.75rem;
}

.mission-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.learn-more-btn {
  background-color: #0765b2 !important;
  border: #0765b2 !important;
  padding: 10px 15px !important;
  margin-top: 20px;
}

.mission-item {
  width: 280px;
  padding: 1.2vw;
  margin: 1.2vw;
  transition: transform 0.4s ease; /* Apply smooth transition for the transform property */
}

.mission-item:hover {
  transform: scale(1.1); /* Enlarge the element by 20% on hover */
}

.mission-item--p {
  padding-top: 1rem;
  font-size: 1rem;
  line-height: 1.3rem;
}

/* ----- events ----- */
.events {
  background-color: #000000;
  color: white;
  padding: 8vh 3vw;
}

.events-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.events-item {
  width: 50ch;
  padding: 1rem;
  overflow: hidden; /* Hide scrollbars */
}

.events--item-1 {
  text-align: left;
}

.events--item-1 p {
  padding-top: 1rem;
}

.events--img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
