.carousel-viewport {
  overflow: hidden;
  width: 100%; /* Set the desired viewport width */
}

.carousel-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    overflow-x: hidden;
    animation: scroll 45s linear infinite;
    margin-top: 2.5vh;
}

.carousel-img {
    height: 33px;
    width: auto;
    filter: saturate(0%) opacity(70%);
    flex-shrink: 0;
    margin: 2vh 1.3vw;
}

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-50%));
    }
}